@font-face {
  font-family: 'Atten New';
  font-style: normal;
  font-weight: 400;
  src: local('Atten New Regular'), url('AttenNewRegular.woff') format('woff');
}

@font-face {
  font-family: 'Atten New';
  font-style: italic;
  font-weight: normal;
  src: local('Atten New Regular Italic'), url('AttenNewRegularItalic.woff') format('woff');
}

@font-face {
  font-family: 'Atten New';
  font-style: normal;
  font-weight: 500;
  src: local('Atten New Medium'), url('AttenNewMedium.woff') format('woff');
}

@font-face {
  font-family: 'Atten New';
  font-style: italic;
  font-weight: 500;
  src: local('Atten New Medium Italic'), url('AttenNewMediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Atten New';
  font-style: normal;
  font-weight: 700;
  src: local('Atten New Bold'), url('AttenNewBold.woff') format('woff');
}

@font-face {
  font-family: 'Atten New';
  font-style: italic;
  font-weight: 700;
  src: local('Atten New Bold Italic'), url('AttenNewBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Atten New';
  font-style: normal;
  font-weight: 900;
  src: local('Atten New ExtraBold'), url('AttenNewExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Atten New';
  font-style: italic;
  font-weight: 900;
  src: local('Atten New ExtraBold Italic'), url('AttenNewExtraBoldItalic.woff') format('woff');
}

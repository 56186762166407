@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

@font-face {
  font-family: 'Atten New';
  font-style: normal;
  font-weight: 400;
  src: local('Atten New Regular'), url(/_next/static/media/AttenNewRegular.79dcfdcf.woff) format('woff');
}

@font-face {
  font-family: 'Atten New';
  font-style: italic;
  font-weight: normal;
  src: local('Atten New Regular Italic'), url(/_next/static/media/AttenNewRegularItalic.6d2980c5.woff) format('woff');
}

@font-face {
  font-family: 'Atten New';
  font-style: normal;
  font-weight: 500;
  src: local('Atten New Medium'), url(/_next/static/media/AttenNewMedium.cc3cd38a.woff) format('woff');
}

@font-face {
  font-family: 'Atten New';
  font-style: italic;
  font-weight: 500;
  src: local('Atten New Medium Italic'), url(/_next/static/media/AttenNewMediumItalic.d6bebcc0.woff) format('woff');
}

@font-face {
  font-family: 'Atten New';
  font-style: normal;
  font-weight: 700;
  src: local('Atten New Bold'), url(/_next/static/media/AttenNewBold.01ba842a.woff) format('woff');
}

@font-face {
  font-family: 'Atten New';
  font-style: italic;
  font-weight: 700;
  src: local('Atten New Bold Italic'), url(/_next/static/media/AttenNewBoldItalic.4269ff95.woff) format('woff');
}

@font-face {
  font-family: 'Atten New';
  font-style: normal;
  font-weight: 900;
  src: local('Atten New ExtraBold'), url(/_next/static/media/AttenNewExtraBold.d7dc9c99.woff) format('woff');
}

@font-face {
  font-family: 'Atten New';
  font-style: italic;
  font-weight: 900;
  src: local('Atten New ExtraBold Italic'), url(/_next/static/media/AttenNewExtraBoldItalic.407aeca9.woff) format('woff');
}

